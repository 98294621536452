import clsx from "clsx";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoadingWheel } from "../base/LoadingWheel";
import { useFreeTierNotification } from "../../hooks/useFreeTierNotification";
import { PendingActionState } from "../../types/Action/Action";

interface ActionSendButtonProps {
  onClick: VoidFunction;
  pendingAction: PendingActionState;
  disabled?: boolean;
  paidTier?: boolean;
}

export function ActionSendButton({
  onClick,
  pendingAction,
  disabled = false,
  paidTier = false,
}: ActionSendButtonProps) {
  const showFreeTierMessage = useFreeTierNotification();

  return (
    <div
      onClick={
        pendingAction !== "idle" || disabled
          ? () => {}
          : !paidTier
            ? showFreeTierMessage
            : onClick
      }
      className={clsx(
        "w-full h-14 md:py-0 md:h-full md:w-full flex items-center justify-center bg-green-500/60 rounded-sm text-white select-none",
        pendingAction === "timedOut"
          ? "hover:cursor-default !bg-red-500/80"
          : pendingAction === "pending" || disabled || !paidTier
            ? "!hover:cursor-default !bg-gray-600 !hover:bg-gray-600"
            : "hover:cursor-pointer bg-green-500/60 hover:bg-green-500/80",
        disabled && "!text-gray-400"
      )}
    >
      {pendingAction === "pending" ? (
        <LoadingWheel />
      ) : pendingAction === "timedOut" ? (
        <FontAwesomeIcon icon={faXmark} fontSize={24} />
      ) : (
        <FontAwesomeIcon icon={faCheck} fontSize={24} />
      )}
    </div>
  );
}
