import { Dispatch, useState } from "react";

import clsx from "clsx";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { Card } from "../base/Card";
import { LineGraph } from "./LineGraph";
import { Button } from "../base/Button";
import { TextField } from "../base/TextField";
import { GUIDtoHEX } from "../../utils/GUIDtoHEX";
import { LoadingWheel } from "../base/LoadingWheel";
import { IHardware } from "../../types/Hardware/Hardware";
import { StatisticStateReducerActions } from "./Statistics";
import { IDataPoint } from "../../types/DataPoint/DataPoint";
import useDataPoints from "../../data/datapoint/useDataPoints";
import {
  accumulatedDataPoints,
  averagedDataPoints,
} from "../../constants/datapointLists";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";
import { IConfiguration } from "../../types/Configuration/Configuration";

const graphableDataPoints = [
  "b810baf0-5deb-46de-0845-08db79626cfc",
  "e86d7b09-fac6-4d71-2b7c-08db6c06bdf6",
  "8b949638-b4b6-4241-4522-08db6c129897",
  "2349732c-a105-4f7c-083b-08db8c5dbb2c",
  "4b43de48-11c7-4eaf-0f2f-08db799086d4",
  "02741e44-81e6-4715-4372-08dc180a3dc2",
  "667e300a-dc19-4b6c-a62f-08dc1ddfd8eb",
  "573db001-9670-41b0-157d-08db929a6792",
  "4477153d-ab74-40d5-0f39-08db799086d4",
  "2ae0c4c6-11d3-4dab-5522-08dba4cbb1ee",
  "fdd89542-8784-4e15-befd-08dbc325e8e0",
  "789cac82-8947-4b6f-befc-08dbc325e8e0",
  "d52ac9c7-c3fb-4ce0-40a6-08dbbaa06d23",
  "02df7f57-612f-4d52-40a7-08dbbaa06d23",
  "0d6db601-588e-4dd6-1a26-08dcf43e360f",
];

interface DatapointChipProps extends IDataPoint {
  selected: boolean;
  onClick: (id: string) => void;
}

function DatapointChip({ id, name, selected, onClick }: DatapointChipProps) {
  return (
    <div
      key={id}
      onClick={() => onClick(id)}
      className={clsx(
        "flex items-center justify-between py-2 px-3 rounded shadow-md bg-gray-100 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-900 hover:cursor-pointer",
        selected ? "bg-gray-300 dark:bg-gray-900" : "",
      )}
    >
      <div className="flex items-center space-x-3">
        <div
          className="w-6 h-6 rounded-lg"
          style={{ backgroundColor: GUIDtoHEX(id) }}
        />
        <p>{name}</p>
      </div>
      <span>
        {selected ? (
          <MinusCircleIcon className="w-5 h-5" />
        ) : (
          <PlusCircleIcon className="w-5 h-5" />
        )}
      </span>
    </div>
  );
}

interface GraphCardProps extends Omit<IConfiguration, "id"> {
  id: string;
  hardware: IHardware;
  dispatch: Dispatch<StatisticStateReducerActions>;
}

export function GraphCard({
  id,
  title,
  dataPointIds,
  hardware,
  dispatch,
}: GraphCardProps) {
  const {
    currentValue: { granularity },
  } = useGraphSettings();
  const { dataPoints } = useDataPoints(hardware.hardwareModelId);
  const [showConfig, setShowConfig] = useState(false);
  const [titleEditing, setTitleEditing] = useState(false);
  const [titleValue, setTitleValue] = useState(title);

  const isUsingAverages = granularity !== "5 Seconds";

  const handleSaveTitle = () => {
    dispatch({
      type: "updateTitle",
      payload: {
        id,
        title: titleValue,
      },
    });
    setTitleEditing(false);
  };

  const handleRemoveGraph = () =>
    dispatch({ type: "removeGraph", payload: { id } });

  const handleDatapointAdd = (dataPointId: string) => {
    dispatch({
      type: "addDataPoint",
      payload: {
        id,
        dataPoint: dataPointId,
      },
    });
  };

  const handleDatapointRemove = (dataPointId: string) => {
    dispatch({
      type: "removeDataPoint",
      payload: {
        id,
        dataPoint: dataPointId,
      },
    });
  };

  return (
    <Card>
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center space-x-4">
          {titleEditing ? (
            <TextField
              value={titleValue}
              onChange={(e) => setTitleValue(e.currentTarget.value)}
            />
          ) : (
            <p className="text-3xl">{title}</p>
          )}
          {showConfig ? (
            titleEditing ? (
              <Button className="h-[42px]" onClick={handleSaveTitle}>
                <CheckIcon className="w-5 h-5" />
              </Button>
            ) : (
              <PencilIcon
                className="w-5 h-5"
                onClick={() => setTitleEditing(true)}
              />
            )
          ) : (
            <></>
          )}
        </div>
        {showConfig ? (
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <ChevronUpIcon
                className="w-6 h-6"
                onClick={() =>
                  dispatch({
                    type: "moveTile",
                    payload: { id, direction: "up" },
                  })
                }
              />
              <ChevronDownIcon
                className="w-6 h-6"
                onClick={() =>
                  dispatch({
                    type: "moveTile",
                    payload: { id, direction: "down" },
                  })
                }
              />
            </div>
            <TrashIcon
              className="w-6 h-6 text-red-400 dark:text-red-800"
              onClick={handleRemoveGraph}
            />
            <CheckIcon
              className="w-8 h-8"
              onClick={() => setShowConfig(false)}
            />
          </div>
        ) : (
          <Cog6ToothIcon
            className="w-8 h-8"
            onClick={() => setShowConfig(true)}
          />
        )}
      </div>
      {showConfig && (
        <div className="mt-2 mb-4 grid grid-cols-1 md:grid-cols-4 gap-2">
          {dataPoints.isLoading ? (
            <LoadingWheel />
          ) : (
            dataPoints.data
              ?.filter((d) =>
                [...accumulatedDataPoints, ...graphableDataPoints].includes(
                  d.id,
                ),
              )
              .filter((d) =>
                isUsingAverages
                  ? [...accumulatedDataPoints, ...averagedDataPoints].includes(
                      d.id,
                    )
                  : true,
              )
              .map((d) => {
                let selected = dataPointIds.includes(d.id);
                return (
                  <DatapointChip
                    {...d}
                    key={`chip-${id}-${d.id}`}
                    selected={selected}
                    onClick={
                      selected ? handleDatapointRemove : handleDatapointAdd
                    }
                  />
                );
              })
          )}
        </div>
      )}
      <LineGraph title={title} identifier={id} dataPointIds={dataPointIds} />
    </Card>
  );
}
