import { useEffect, useState } from "react";

import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { ActionSendButton } from "./ActionSendButton";

import { BaseActionProps } from "../../types/Action/Action";
import { useFreeTierNotification } from "../../hooks/useFreeTierNotification";

interface ValueActionProps extends BaseActionProps {
  currentValue: number;
  min?: number;
  max?: number;
  precision?: number;
  step?: number;
  unit?: string;
}

/**
 * A value input with simple up/down pickers, takes a variety of props to make it suitable for different situations
 *
 * @component
 * @param {number} currentValue required - gives a starting point for the control.
 * @param {number} min stops the control going below the given value.
 * @param {number} max stops the control going above the given value.
 * @param {number} precision defaults to 0 - a number of decimal places the value should be accurate to.
 * @param {number} step amount to increment/decrement the value by.
 * @param {string} unit - a string to display after the numerical value - eg °C.
 * @param {function} onApply required - function to run when the user clicks apply.
 * @param {string} actionId required - ID of this action.
 * @param {boolean} pendingAction requited - if true disables send button
 */

const defaultMin = -100;
const defaultMax = 100;

let valueUpTimer: NodeJS.Timer;
let valueUpTimeout: NodeJS.Timeout;
let valueDownTimer: NodeJS.Timer;
let valueDownTimeout: NodeJS.Timeout;

export function ValueAction({
  currentValue,
  min = defaultMin,
  max = defaultMax,
  precision = 0,
  step = 1,
  unit,
  onApply,
  actionId,
  pendingAction,
  disabled = false,
  paidTier = false,
}: ValueActionProps) {
  const showFreeTierMessage = useFreeTierNotification();
  const _min = min === null ? defaultMin : min;
  const _max = max === null ? defaultMax : max;
  const [blockUpdate, setBlockUpdate] = useState(false);
  const [value, setValue] = useState(currentValue);

  const handleApply = () => {
    onApply(String(value), actionId);
    setBlockUpdate(false);
  };

  const handleValueUp = () => {
    setValue((cv) => {
      if (cv < _max) {
        return cv + step;
      } else {
        return cv;
      }
    });
    setBlockUpdate(true);
  };

  const handleValueDown = () => {
    setValue((cv) => {
      if (cv > _min) {
        return cv - step;
      } else {
        return cv;
      }
    });
    setBlockUpdate(true);
  };

  const handleValueUpMouseDown = () => {
    handleValueUp();
    valueUpTimeout = setTimeout(
      () => (valueUpTimer = setInterval(handleValueUp, 100)),
      500
    );
  };

  const handleValueUpMouseUp = () => {
    clearTimeout(valueUpTimeout);
    clearInterval(valueUpTimer);
  };

  const handleValueDownMouseDown = () => {
    handleValueDown();
    valueDownTimeout = setTimeout(
      () => (valueDownTimer = setInterval(handleValueDown, 100)),
      500
    );
  };

  const handleValueDownMouseUp = () => {
    clearTimeout(valueDownTimeout);
    clearInterval(valueDownTimer);
  };

  useEffect(() => {
    if (!blockUpdate && pendingAction !== "pending") {
      setValue(currentValue);
    }
  }, [blockUpdate, currentValue, pendingAction]);

  const isCurrentValue = value === currentValue;
  const isMax = value === _max;
  const isMin = value === _min;

  return (
    <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
      <div className="md:col-span-6">
        <div className="w-full h-14 flex items-center border border-primary rounded-sm">
          <div
            className={clsx(
              "px-4 h-full pt-2 pb-1.5 flex-1 text-black rounded-l-sm text-4xl text-right font-medium",
              pendingAction !== "idle" || disabled || !paidTier
                ? "bg-gray-200"
                : "bg-white",
              disabled && "text-gray-400"
            )}
          >
            {precision ? value.toFixed(precision) : value} {unit ? unit : ""}
          </div>
          <div className="w-16 h-full bg-primary rounded-r-sm text-white">
            <div
              onMouseDown={
                Boolean(isMax || pendingAction !== "idle" || disabled)
                  ? () => {}
                  : !paidTier
                    ? showFreeTierMessage
                    : handleValueUpMouseDown
              }
              onMouseUp={handleValueUpMouseUp}
              onMouseLeave={handleValueUpMouseUp}
              className={clsx(
                "w-full h-1/2 flex items-center justify-center border-white select-none hover:cursor-pointer hover:bg-white/10",
                Boolean(
                  isMax || pendingAction !== "idle" || disabled || !paidTier
                ) &&
                  "text-gray-200 bg-gray-400 hover:!cursor-default hover:!bg-gray-400"
              )}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
            <div
              onMouseDown={
                Boolean(isMin || pendingAction !== "idle" || disabled)
                  ? () => {}
                  : !paidTier
                    ? showFreeTierMessage
                    : handleValueDownMouseDown
              }
              onMouseUp={handleValueDownMouseUp}
              onMouseLeave={handleValueDownMouseUp}
              className={clsx(
                "w-full h-1/2 flex items-center justify-center border-white select-none hover:cursor-pointer hover:bg-white/10",
                Boolean(
                  isMin || pendingAction !== "idle" || disabled || !paidTier
                ) &&
                  "text-gray-200 bg-gray-400 hover:!cursor-default hover:!bg-gray-400"
              )}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
      <div className="md:col-span-2">
        <ActionSendButton
          onClick={handleApply}
          pendingAction={pendingAction}
          disabled={
            pendingAction === "timedOut" ? false : isCurrentValue || disabled
          }
          paidTier={paidTier}
        />
      </div>
    </div>
  );
}
