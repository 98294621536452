import { useEffect, useState } from "react";

import { Card } from "../../components/base/Card";
import { Select } from "../../components/base/Select";
import useHardware from "../../data/hardware/useHardware";
import { IHardware } from "./../../types/Hardware/Hardware";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { ReadingsDisplay } from "../../components/LiveView/ReadingsDisplay";
import { DeviceStatusBar } from "../../components/LiveView/DeviceStatusBar";
import { PendingActionContextProvider } from "../../contexts/PendingActionContext";
import { useHardwareLookups } from "../../hooks/useHardwareLookups";
import { LiveReadingContextProvider } from "../../contexts/LiveReadingContext";
import { useUserContext } from "../../contexts/UserContext";
import { ViewAsSelection } from "../../components/LiveView/ViewAsSelection";

export type LiveReadingsViewAs = "customer" | "installer" | "superadmin";

export function LiveViewPage() {
  const {
    loading: userLoading,
    isSuperAdmin,
    isInstaller,
    installerId,
  } = useUserContext();
  const { setPageTitle } = usePageTitle();
  const { hardware: allHardware } = useHardware();
  const { isLoading, manufacturerLookup, modelLookup } = useHardwareLookups();
  const [selectedHardware, setSelectedHardware] = useState<
    IHardware | undefined
  >(undefined);
  const [noHardware, setNoHardware] = useState(false);
  const [viewAs, setViewAs] = useState<LiveReadingsViewAs>();
  const [viewOpts, setViewOpts] =
    useState<Partial<Record<LiveReadingsViewAs, string>>[]>();

  const handleHardwareChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const hardwareId = e.currentTarget.value;
    const foundHardware = allHardware.data?.find(
      (hardware) => hardware.id === hardwareId
    );

    setSelectedHardware(foundHardware);
  };

  useEffect(() => {
    setPageTitle("Live Readings", false);
  }, [setPageTitle]);

  useEffect(() => {
    if (!allHardware.isLoading && allHardware.data?.length) {
      if (!selectedHardware) {
        setSelectedHardware(allHardware.data[0]);
      }
    } else if (!allHardware.isLoading) {
      setNoHardware(true);
    }
  }, [selectedHardware, allHardware.isLoading, allHardware.data]);

  useEffect(() => {
    if (!userLoading && !viewOpts && !viewAs) {
      let viewOpts: Partial<Record<LiveReadingsViewAs, string>>[] = [];

      Boolean(isInstaller || installerId !== undefined) &&
        viewOpts.push({ installer: "Installer" });

      viewOpts.push({ customer: "Customer" });

      isSuperAdmin && viewOpts.push({ superadmin: "Super Admin" });

      setViewAs(
        Object.keys(Object.values(viewOpts)[0])[0] as any as LiveReadingsViewAs
      );
      setViewOpts(viewOpts);
    }
  }, [userLoading, viewOpts, viewAs, installerId, isInstaller, isSuperAdmin]);

  return noHardware ? (
    <Card>
      <div className="h-24 flex items-center justify-center">
        <p>No Atmo devices registered to this account</p>
      </div>
    </Card>
  ) : !selectedHardware || isLoading || userLoading ? (
    <SectionLoading />
  ) : (
    <PendingActionContextProvider>
      <LiveReadingContextProvider hardwareId={selectedHardware.id}>
        <Card className="mb-6 block space-y-3 md:flex md:flex-row md:items-center md:space-between md:space-y-0">
          <div className="w-full block mb-4 md:mb-0 md:flex md:space-x-4 md:items-center">
            <p className="text-sm mb-2 md:mb-0">Atmo Device</p>
            <Select className="w-full md:w-64" onChange={handleHardwareChange}>
              {allHardware.data?.map((hardware) => (
                <option key={hardware.id} value={hardware.id}>
                  {hardware.name}
                </option>
              ))}
            </Select>
            <p className="my-4 md:my-0">
              {`${
                manufacturerLookup(selectedHardware.hardwareManufacturerId)
                  ?.name
              } |
            ${modelLookup(selectedHardware.hardwareModelId)?.name}`}
            </p>
          </div>
          <ViewAsSelection
            viewAsOptions={viewOpts!}
            viewAs={viewAs!}
            setViewAs={setViewAs}
          />
          <DeviceStatusBar hardware={selectedHardware} />
        </Card>
        <ReadingsDisplay viewAs={viewAs!} hardware={selectedHardware} />
      </LiveReadingContextProvider>
    </PendingActionContextProvider>
  );
}
