import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";

import { LiveGraph } from "./LiveGraph";
import { ValueAction } from "./ValueAction";
import { ToggleAction } from "./ToggleAction";
import { SelectAction } from "./SelectAction";
import { IUser } from "../../types/User/User";
import { Card } from "../../components/base/Card";
import useApiHelper from "../../hooks/useApiHelper";
import { addTimeout } from "../../utils/addTimeout";
import useDevice from "../../data/device/useDevice";
import { IHardware } from "../../types/Hardware/Hardware";
import { useUserContext } from "../../contexts/UserContext";
import { IReadingDataPartial } from "../../types/DataPoint/Reading";
import useOrganisation from "../../data/organisation/useOrganisation";
import {
  IProcessedReading,
  useReadingsProcessor,
} from "../../hooks/useReadingProcessor";
import { IActionProfile, InputType } from "../../types/Action/ActionProfile";
import {
  PendingAction,
  usePendingActions,
} from "../../contexts/PendingActionContext";
import useOrganisationUsers from "./../../data/organisationUsers/useOrganisationUsers";
import { useLiveReading } from "../../contexts/LiveReadingContext";
import { PendingActionState } from "../../types/Action/Action";

interface ReadingCardProps {
  hardware: IHardware;
  timestamp: string;
  initialProcessedReading: IProcessedReading;
}

export function ReadingCard({
  hardware,
  timestamp,
  initialProcessedReading,
  ...rest
}: ReadingCardProps) {
  const { post } = useApiHelper();
  const { hasPendingAction, addPendingAction, removePendingAction } =
    usePendingActions();
  const { device } = useDevice(hardware.deviceId ?? "");
  const { processorReady, readingProcessor } = useReadingsProcessor(
    hardware.hardwareModelId
  );
  const { latest } = useLiveReading();
  const { organisation } = useOrganisation();
  const { users } = useOrganisationUsers();
  const { userId } = useUserContext();
  const [organisationUser, setOrganisationUser] = useState<IUser>();
  const [processedReading, setProcessedReading] = useState(
    initialProcessedReading
  );
  const [pendingActionState, setPendingActionState] =
    useState<PendingActionState>("idle");
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const actionTimedOutTimeOut = useRef<NodeJS.Timeout>();

  const handleApplyAction = (val: string, actionId: string) => {
    const valueParts = val.split(".");
    // Check to see if we have more than one item after split (we have a . in the string)
    // Then check to see if the string after the . is not 0
    const isFloat =
      valueParts.length > 1 ? (valueParts[1] !== "0" ? true : false) : false;
    const value = isFloat ? parseFloat(val) : parseInt(val);

    setPendingActionState("pending");
    addPendingAction({
      dataPointId: processedReading?.dataPoint.id,
      expectedValue: value,
      timeout: addTimeout(
        processedReading?.dataPoint.actions[0].responseTimeout === 0
          ? 60
          : processedReading?.dataPoint.actions[0].responseTimeout!
      ),
    });

    post(`/v1/action/${hardware.id}`, {
      value,
      action: actionId,
    }).then(() => {
      //TODO: add notification here
    });
  };

  const getAction = (action: IActionProfile) => {
    let conencted = device.data
      ? device.data![0].device.cloud.connected
      : false;

    const props = {
      onApply: handleApplyAction,
      actionId: action.id,
      pendingAction: pendingActionState,
      currentValue: processedReading?.reading.transformedValue!,
      disabled:
        !conencted || organisation.data?.readRole === organisationUser?.roleId,
      paidTier: hardware.subscriptionId !== null,
    };

    switch (action.inputType) {
      case InputType.None:
        return <></>;
      case InputType.Select:
        return (
          <SelectAction
            {...props}
            options={processedReading?.dataPoint.options!}
          />
        );
      case InputType.Toggle:
        let toggleOpts = processedReading?.dataPoint.options.filter(
          (opt) => !opt.readOnly
        );

        if (toggleOpts?.length! < 2) {
          return <></>;
        } else {
          return (
            <ToggleAction
              {...props}
              leftLabel={toggleOpts![0].label}
              rightLabel={toggleOpts![1].label}
            />
          );
        }
      case InputType.Value:
      default:
        return (
          <ValueAction
            {...props}
            step={action.step}
            min={processedReading?.dataPoint.profiles[0].minimumValue}
            max={processedReading?.dataPoint.profiles[0].maximumValue}
            precision={processedReading?.dataPoint.profiles[0].precision}
            unit={processedReading?.dataPoint.unit}
          />
        );
    }
  };

  useEffect(() => {
    if (!organisationUser && !organisation.isLoading && !users.isLoading) {
      setOrganisationUser(users.data?.find((u) => u.id === userId));
    }
  }, [organisation.isLoading, users.isLoading]);

  useEffect(() => {
    // Find the reading for this card
    let reading = latest?.readingData.find(
      (rd) => rd.dataPointId === initialProcessedReading.dataPoint.id
    );

    if (reading) {
      // Get the updated value and the expected value
      let update = readingProcessor(reading!);
      let pendingAction = hasPendingAction(reading!.dataPointId);

      // If a pending action is stored
      if (pendingAction) {
        if (pendingAction === "timedOut") {
          setPendingActionState("timedOut");
          actionTimedOutTimeOut.current = setTimeout(() => {
            setPendingActionState("idle");
          }, 5000);
        } else {
          let pa = pendingAction as PendingAction;

          if (update?.reading.transformedValue === pa.expectedValue) {
            // Update worked, clear flag
            setPendingActionState("idle");
            removePendingAction(reading!.dataPointId);
          }
        }
      }

      // Either way update the value for display.
      setProcessedReading(update!);
      !shouldDisplay && setShouldDisplay(true);
    } else {
      console.warn(
        "Could not find a dataPoint for card",
        initialProcessedReading.dataPoint.id
      );
      setShouldDisplay(false);
    }

    return () => {
      if (actionTimedOutTimeOut.current) {
        clearTimeout(actionTimedOutTimeOut.current);
      }
    };
  }, [latest, readingProcessor]);

  if (
    !processorReady ||
    organisation.isLoading ||
    users.isLoading ||
    !shouldDisplay
  ) {
    return <></>;
  } else {
    if (!processedReading) {
      return <></>;
    } else {
      const displayIcon = processedReading.dataPoint.icon
        ? (processedReading.dataPoint.icon.split(",") as [IconPrefix, IconName])
        : (["fas", "question"] as [IconPrefix, IconName]);

      const getHeight = () => {
        if (
          processedReading.dataPoint.actions.length > 0 &&
          processedReading.dataPoint.graphEnable
        ) {
          return 352;
        } else if (processedReading.dataPoint.actions.length > 0) {
          return 176;
        } else if (processedReading.dataPoint.graphEnable) {
          return 264;
        } else {
          return 88;
        }
      };

      return (
        <Card {...rest} className={`h-[${getHeight()}]`}>
          <div className="p-1 grid grid-cols-6 gap-2">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={displayIcon}
                className="h-auto max-h-20 aspect-square text-gray-500 dark:text-gray-300"
              />
            </div>
            <div className="col-span-5">
              <h4 className="text-2xl text-right">
                {processedReading.dataPoint.name}
              </h4>
              <h2 className="text-5xl text-right">
                {processedReading.displayString}
              </h2>
            </div>
          </div>
          {Boolean(
            processedReading.dataPoint.graphEnable && hardware !== undefined
          ) && (
            <div className="mt-4">
              <LiveGraph
                hardwareId={hardware.id}
                dataPoint={processedReading.dataPoint}
                endDate={latest!.timestamp}
              />
            </div>
          )}
          {processedReading.dataPoint.actions.length > 0 && (
            <div className="mt-4">
              {getAction(processedReading.dataPoint.actions[0])}
            </div>
          )}
        </Card>
      );
    }
  }
}
