import { Dispatch, useEffect, useState } from "react";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { Card } from "../base/Card";
import { Select } from "../base/Select";
import { TextField } from "../base/TextField";
import { LoadingWheel } from "../base/LoadingWheel";
import { IHardware } from "../../types/Hardware/Hardware";
import { StatisticStateReducerActions } from "./Statistics";
import { IDataPoint } from "../../types/DataPoint/DataPoint";
import useDataPoints from "../../data/datapoint/useDataPoints";
import { useGraphData } from "../../data/datapoint/useGraphData";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";
import { IConfiguration } from "../../types/Configuration/Configuration";
import {
  accumulatedDataPoints,
  averagedDataPoints,
} from "../../constants/datapointLists";
import { Button } from "../base/Button";
import { Alert } from "../base/Alert";
import { getGranularities } from "../../utils/graphUtils";
import { Duration } from "luxon";

interface AverageCardProps extends Omit<IConfiguration, "id"> {
  id: string;
  hardware: IHardware;
  dispatch: Dispatch<StatisticStateReducerActions>;
}

export function StatCard({
  type,
  title,
  id,
  dataPointIds,
  dispatch,
}: AverageCardProps) {
  const {
    currentValue: { hardware, timespan, granularity },
  } = useGraphSettings();
  const { getGraphData } = useGraphData();
  const { dataPoints } = useDataPoints(hardware!.hardwareModelId);
  const [showConfig, setShowConfig] = useState(false);
  const [titleEditing, setTitleEditing] = useState(false);
  const [titleValue, setTitleValue] = useState(title);
  const [selectedDataPoint, setSelectedDataPoint] = useState<string>(
    dataPointIds[0] ?? "-1"
  );
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<string | number>();
  const [datapoint, setDataPoint] = useState<IDataPoint>();
  const [noDataPoint, setNoDataPoint] = useState(false);
  const dataPointOptions =
    type === "average" ? averagedDataPoints : accumulatedDataPoints;

  const handleSaveTitle = () => {
    dispatch({
      type: "updateTitle",
      payload: {
        id,
        title: titleValue,
      },
    });
    setTitleEditing(false);
  };

  const handleRemoveAverage = () => {
    dispatch({
      type: "removeStat",
      payload: {
        id,
      },
    });
  };

  const findLowestGranularity = () =>
    getGranularities(timespan.endDate.diff(timespan.startDate))[0];

  const findDataPoint = async () =>
    dataPoints.data!.find((d) => d.id === dataPointIds[0]);

  const calculateAccumulation = async () => {
    const data = await getGraphData({
      hardwareId: hardware!.id,
      timespan,
      granularity: findLowestGranularity(),
      datapoints: dataPointIds,
    });

    console.log(data[0].data);

    const total = data[0].data.reduce(
      (acc, curr) =>
        (acc += curr.readingData[0] ? curr.readingData[0].transformedValue : 0),
      0
    );

    return total;
  };

  const calculateAverage = async () => {
    const data = await getGraphData({
      hardwareId: hardware!.id,
      timespan,
      granularity: findLowestGranularity(),
      datapoints: dataPointIds,
    });

    const total = data[0].data.reduce(
      (acc, curr) =>
        (acc += curr.readingData[0] ? curr.readingData[0].transformedValue : 0),
      0
    );

    return total / data[0].data.length;
  };

  useEffect(() => {
    setLoading(true);

    if (!dataPoints.isLoading && dataPoints.data && dataPointIds.length > 0) {
      const calculateFn =
        type === "average" ? calculateAverage : calculateAccumulation;
      Promise.all([calculateFn(), findDataPoint()]).then((d) => {
        const dataPoint = d[1];

        const val = dataPoint?.profiles[0]
          ? d[0].toFixed(dataPoint.profiles[0].precision)
          : d[0].toFixed(2);

        noDataPoint && setNoDataPoint(false);
        setValue(val);
        setDataPoint(dataPoint);
        setLoading(false);
      });
    } else if (dataPointIds.length <= 0) {
      setNoDataPoint(true);
      setLoading(false);
    }
  }, [
    type,
    dataPoints.isLoading,
    dataPoints.data,
    dataPointIds,
    hardware,
    timespan,
    granularity,
  ]);

  return (
    <Card>
      <div className="flex items-start justify-between mb-3">
        <div className="flex items-center space-x-4">
          {titleEditing ? (
            <TextField
              value={titleValue}
              onChange={(e) => setTitleValue(e.currentTarget.value)}
            />
          ) : (
            <p className="text-3xl">{title}</p>
          )}
          {showConfig ? (
            titleEditing ? (
              <Button className="h-[42px]" onClick={handleSaveTitle}>
                <CheckIcon className="w-5 h-5" />
              </Button>
            ) : (
              <PencilIcon
                className="w-5 h-5"
                onClick={() => setTitleEditing(true)}
              />
            )
          ) : (
            <></>
          )}
        </div>
        {showConfig ? (
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <ChevronUpIcon
                className="w-6 h-6"
                onClick={() =>
                  dispatch({
                    type: "moveTile",
                    payload: { id, direction: "up" },
                  })
                }
              />
              <ChevronDownIcon
                className="w-6 h-6"
                onClick={() =>
                  dispatch({
                    type: "moveTile",
                    payload: { id, direction: "down" },
                  })
                }
              />
            </div>
            <TrashIcon
              className="w-6 h-6 text-red-400 dark:text-red-800"
              onClick={handleRemoveAverage}
            />
            <CheckIcon
              className="w-8 h-8"
              onClick={() => setShowConfig(false)}
            />
          </div>
        ) : (
          <Cog6ToothIcon
            className="w-8 h-8"
            onClick={() => setShowConfig(true)}
          />
        )}
      </div>
      {showConfig && (
        <div className="w-full flex flex-row items-center space-x-4 pt-2 mb-4">
          <p className="text-sm">Data point</p>
          <Select
            value={selectedDataPoint}
            onChange={(e) =>
              e.currentTarget.value !== "-1" &&
              setSelectedDataPoint(e.currentTarget.value)
            }
            className="w-64"
          >
            <option value="-1">Choose...</option>
            {dataPointOptions.map((dataPoint) => {
              const label = dataPoints.data?.find(
                (dp) => dp.id === dataPoint
              )?.name;

              return <option value={dataPoint}>{label}</option>;
            })}
          </Select>
          <Button
            className="h-[42px]"
            disabled={selectedDataPoint === "-1"}
            onClick={() =>
              dispatch({
                type: "updateStatConfig",
                payload: { id, dataPoint: selectedDataPoint },
              })
            }
          >
            <CheckIcon className="w-5 h-5" />
          </Button>
        </div>
      )}
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="text-center space-y-2 my-8">
            <LoadingWheel />
            <p>This may take a while</p>
          </div>
        </div>
      ) : noDataPoint ? (
        <div className="h-[200px] flex items-center justify-center">
          <p>Choose a Data point to get started</p>
        </div>
      ) : (
        <div>
          <p className="text-xl pb-1 pt-4">{datapoint?.name}</p>
          <p className="text-5xl">
            {value} {datapoint?.unit ? datapoint.unit : ""}
          </p>
        </div>
      )}
    </Card>
  );
}
