import { useEffect, useState } from "react";

import clsx from "clsx";
import ClickAwayListener from "react-click-away-listener";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { ActionSendButton } from "./ActionSendButton";

import { BaseActionProps } from "../../types/Action/Action";
import { IDataPointOption } from "../../types/DataPoint/DataPointOption";
import { useFreeTierNotification } from "../../hooks/useFreeTierNotification";

interface SelectActionProps extends BaseActionProps {
  currentValue: number;
  options: IDataPointOption[];
}

/**
 * A value input with simple up/down pickers, takes a variety of props to make it suitable for different situations
 *
 * @component
 * @param {number} currentValue required - gives a starting point for the control.
 * @param {IDataPointOption[]} options - an array of options to render in the select.
 * @param {function} onApply required - function to run when the user clicks apply.
 * @param {string} actionId required - ID of this action.
 * @param {boolean} pendingAction requited - if true disables send button
 */

export function SelectAction({
  currentValue,
  options,
  onApply,
  actionId,
  pendingAction,
  disabled = false,
  paidTier = false,
}: SelectActionProps) {
  const showFreeTierMessage = useFreeTierNotification();
  const resolveValueToOption = (value: number) =>
    options.find((opt) => opt.value === value);

  const [open, setOpen] = useState(false);
  const [blockUpdate, setBlockUpdate] = useState(false);
  const [value, setValue] = useState(currentValue);
  const [selectedOption, setSelectedOption] = useState(
    resolveValueToOption(currentValue)
  );

  const handleApply = () => {
    onApply(String(value), actionId);
    setBlockUpdate(false);
  };

  const handleChangeValue = (value: number) => {
    setValue(value);
    setSelectedOption(resolveValueToOption(value));
    setBlockUpdate(true);
  };

  useEffect(() => {
    if (!blockUpdate && pendingAction !== "pending") {
      setValue(currentValue);
      setSelectedOption(resolveValueToOption(currentValue));
    }
  }, [blockUpdate, currentValue, pendingAction]);

  const isCurrentValue = value === currentValue;

  return (
    <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
      <div className="md:col-span-6">
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            onClick={
              pendingAction !== "idle" || disabled
                ? () => {}
                : !paidTier
                  ? showFreeTierMessage
                  : () => setOpen(!open)
            }
            className="w-full min-h-14 flex items-center bg-primary border border-primary rounded-sm"
          >
            <div
              className={clsx(
                "px-4 h-full pt-2 pb-1.5 flex-1 text-black rounded-l-sm text-4xl text-right font-medium",
                pendingAction !== "idle" || disabled || !paidTier
                  ? "bg-gray-200 hover:cursor-default"
                  : "bg-white hover:cursor-pointer"
              )}
            >
              <div
                className={clsx(
                  "max-w-[347px]",
                  disabled ? "text-gray-400" : ""
                )}
              >
                {selectedOption?.label}
              </div>
              <div
                className={clsx(
                  open ? "" : "hidden",
                  "max-w-[347px] w-full -mx-4 max-h-80 overflow-y-auto divide-y border-y absolute z-50 bg-white"
                )}
              >
                {options.map((opt) => (
                  <div
                    className={clsx(
                      "px-4 pb-1 pt-1.5 hover:bg-gray-200 hover:cursor-pointer",
                      opt.readOnly &&
                        "bg-gray-300 hover:bg-gray-300 hover:!cursor-default"
                    )}
                    onClick={
                      !opt.readOnly
                        ? () => handleChangeValue(opt.value)
                        : () => {}
                    }
                  >
                    {opt.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="min-h-14 h-14">
              <div
                onClick={
                  disabled
                    ? () => {}
                    : !paidTier
                      ? showFreeTierMessage
                      : () => setOpen(!open)
                }
                className={clsx(
                  "w-16 min-h-14 h-full flex items-center justify-center rounded-r-sm text-white select-none hover:cursor-pointer hover:bg-white/10",
                  (pendingAction !== "idle" || disabled || !paidTier) &&
                    "text-gray-200 bg-gray-400 hover:!cursor-default hover:!bg-gray-400"
                )}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
      <div className="md:col-span-2">
        <ActionSendButton
          onClick={handleApply}
          pendingAction={pendingAction}
          disabled={
            pendingAction === "timedOut" ? false : isCurrentValue || disabled
          }
          paidTier={paidTier}
        />
      </div>
    </div>
  );
}
