export const userQueryKeys = {
  configurations: (type: number) => ["user", "configurations", type],
  organisations: () => ["user", "organisations"],
};

export const actionQueryKeys = {
  byModel: (hardwareModelId?: string) =>
    hardwareModelId ? ["actions", hardwareModelId] : ["actions"],
  superAdminAll: () => ["superadmin", "actions"],
  create: () => [...actionQueryKeys.superAdminAll(), "create"],
  update: () => [...actionQueryKeys.superAdminAll(), "update"],
  delete: () => [...actionQueryKeys.superAdminAll(), "delete"],
};

export const actionProfileQueryKeys = {
  superAdminAll: () => ["superadmin", "action", "profile"],
  superAdminByModel: (hardwareModelId: string) => [
    ...actionProfileQueryKeys.superAdminAll(),
    hardwareModelId,
  ],
  create: () => [...actionProfileQueryKeys.superAdminAll(), "create"],
  update: () => [...actionProfileQueryKeys.superAdminAll(), "update"],
  delete: () => [...actionProfileQueryKeys.superAdminAll(), "delete"],
};

export const actionTransformationQueryKeys = {
  superAdminAll: () => ["superadmin", "action", "transformation"],
  superAdminByModel: (hardwareModelId: string) => [
    ...actionProfileQueryKeys.superAdminAll(),
    hardwareModelId,
  ],
  create: () => [...actionTransformationQueryKeys.superAdminAll(), "create"],
  update: () => [...actionTransformationQueryKeys.superAdminAll(), "update"],
  delete: () => [...actionTransformationQueryKeys.superAdminAll(), "delete"],
};

export const organisationTariffQueryKeys = {
  all: () => ["organisation", "tariffs"],
  update: () => [...organisationTariffQueryKeys.all(), "update"],
};

export const metaDataQueryKeys = {
  all: () => ["metadata"],
};
